import * as React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

const Footer = ({ data }) => {

  return (
    <footer className="">
      <div className="footer-wrapper inner-content-wrapper main-grid-columns">
        <h4>{data.title}</h4>
        <p className="footer-address">{data.address}</p>
        <div className="footer-menu">
          <ul>
            <li>
              <Link
                to="/"
              >
                Start
              </Link>
            </li>
            <li>
            <Link
                to="/hausgeburt"
              >
                Hausgeburt
              </Link>
            </li>
            <li>
              <a href="https://vulvarium.hebamio.de/anmeldung" target="_blank">Anfrage</a>
            </li>
            <li>
              <Link
                to="/impressum"
              >
                Impressum
              </Link>
            </li>
          </ul>
        </div>
        <div className="social main-grid-columns">
          <div className="social-entry">
            <a href="https://www.hebammenverband.de/startseite/" target="_blank" rel="noreferrer">
            <span>Mitglied im:</span><br />
            <StaticImage src="../images/Deutscher_Hebammenverband_logo.svg" width="156"  style={{marginTop:"var(--dist-20)", transform:"translate(-23%, 0)"}}></StaticImage>
            </a>
          </div>
          <div className="social-entry">
            <a href="https://www.instagram.com/ulmerhebamme/" target="_blank" rel="noreferrer">
            <span>Folge mir auf Instagram!</span><br />
            <StaticImage src="../images/glyph-logo_May2016.svg" width="48" height="48" style={{marginTop:"1.5rem"}}></StaticImage>
            </a>
          </div>
        </div>
      </div>
    </footer >
  )
}


export default Footer
