import * as React from "react"
import { Link } from "gatsby"



const MainMenu = () => {

    return (
        <>
            <div className='main-menu'>
                <Link
                    to="/"
                    activeClassName="active"
                >
                    Start
                </Link>
                <Link
                    to="/hausgeburt"
                    activeClassName="active"
                >
                    Hausgeburt
                </Link>

                <a href="https://vulvarium.hebamio.de/anmeldung" target="_blank">Anfrage</a>

                <Link
                    to="/impressum"
                    activeClassName="active"
                >
                    Impressum
                </Link>
            </div>

        </>
    )
}

export default MainMenu